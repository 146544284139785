<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          调拨单号：[#{{data.item.outbound_id}}]{{data.item.outbound_no}}
<!--          <br>-->
<!--          类型：{{getCodeLabel('allot_type', data.item.allot_type)}}-->
<!--          <br>-->
<!--          发起：{{data.item.duty_name}}-->
        </template>

        <template #cell(fromwarehouse_id)="data">
          调出：{{data.item.fromwarehouse_name}}
<!--          {{data.item.from_contact}}  {{data.item.from_mobile}}-->
<!--          <br>-->
<!--          {{data.item.from_location}}-->
        </template>

        <template #cell(towarehouse_id)="data">
          调入：{{data.item.towarehouse_name}}
          <!--          {{data.item.to_contact}}  {{data.item.to_mobile}}-->
          <!--          <br>-->
          <!--          {{data.item.to_location}}-->
        </template>

        <template #cell(product_id)="data">
          [#{{data.item.product_id}}]  {{data.item.name}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toTime(data.item.production_date)+
              '<br>'+'结束：'+toTime(data.item.expiry_date)+
              '<br>'+'类型：'+getCodeLabel('allot_type', data.item.allot_type)+
              '<br>'+'发起：'+data.item.duty_name+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(batch_no)="data">
          批次：{{data.item.batch_no}}
          <br>
          开始：{{toTime(data.item.production_date)}}
          <br>
          结束：{{toTime(data.item.expiry_date)}}
        </template>

        <template #cell(warestatus)="data">
          {{data.item.warestatus}}
        </template>

        <template #cell(allot_time)="data">
          {{toDate(data.item.allot_time)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
<!--          <br>-->
<!--          {{toTime(data.item.allot_time)}}-->
        </template>

        <!-- Column: Actions -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import stockallotoutbounditemUseList from './stockallotoutbounditemUseList'
import stockallotoutbounditemStore from './stockallotoutbounditemStore'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    outbound_id :{
      type : Number,
      default : 0
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('stockallotoutbounditem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('stockallotoutbounditem')) store.registerModule('stockallotoutbounditem', stockallotoutbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockallotoutbounditem')) store.unregisterModule('stockallotoutbounditem')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stockallotoutbounditemUseList({
          outbound_id: props.outbound_id
        }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI

      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
